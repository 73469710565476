
import Vue from 'vue';
import { mapActions, mapMutations, mapGetters, mapState } from 'vuex';
import { VIEW_COMPANIES, UPDATE_COMPANIES } from '@/consts/permissions';
import each from 'lodash/each';
import groupBy from 'lodash/groupBy';
import { userCheck } from '@/api/fidaApi';
import {
  reportMenu,
  VMDMenu,
  toolsMenu,
  academyMenu,
  settingMenu,
  SETTING_MENU_ITEMS
} from '../../util/mockData/menuList';
import { addWeeks, startOfWeek } from 'date-fns';
import { handleErrorUI } from '@/util/error';
import CryptoJS from 'crypto-js';
import { getAuthoritiesObj } from '@/util/authoritiesCheck';
import { checkPermission } from '@/api/utils';
import { APIUpdateLangSSO } from '@/api/authSSO';
import store from '@/store';
import Gleap from 'gleap';

const stateMap = {
  [VIEW_COMPANIES]: 'settings:company',
  [UPDATE_COMPANIES]: 'settings:company'
};

export default {
  name: 'ContainerHeader',
  data() {
    return {
      isDemo: false,
      isuserCheck: false,
      isPulseAllow: false,
      imageURL: '',
      user: {},
      releaseShow: false,
      accessibleModules: {},
      showNews: false,
      showVMD: false,
      showTools: false,
      showSetting: true,
      accessibleReportMenu: [],
      accessibleVMDMenu: [],
      accessibleToolsMenu: [],
      accessibleAcademyMenu: [],
      accessibleSettingMenu: [],
      viewPermission: {
        'settings:company': true
      },
      toggleLeftMiniDrawer: true,
      isEventAdd: false,
      expanded: false,
      toggleLeftDrawer: false,
      link: '/',
      appStatus: false
    };
  },
  computed: {
    ...mapState('user', [
      'profile',
      'userPermissions',
      'defaultSettings',
      'clientProfile'
    ]),
    ...mapState('store', ['stores']),
    ...mapGetters('user', ['getStoreNameList', 'getStoreCodeList']),
    ...mapGetters('user', ['getLocale', 'isAuthenticated', 'getToken']),
    ...mapGetters('tenant', ['config']),
    showSettingsItem() {
      return SETTING_MENU_ITEMS.map(v => this.viewAllowed(v)).some(Boolean);
    },
    admin() {
      let checkAdmin = this.$store.state?.user?.profile?.email.startsWith(
        'botadmin@'
      );
      return checkAdmin;
    }
  },
  watch: {
    profile: function(newValue) {
      this.accessibleModules = this.profile.accessibleModules;
    },
    userPermissions() {
      this.generateAllMenus();
    },
    stores() {
      this.updateMixpanelUser();
    },
    $route: function(newValue) {
      this.link = newValue.name;
    }
  },
  async created() {
    try {
      this.isuserCheck = userCheck();
      if (
        localStorage.getItem('tenentID') == 'A9Lqp7y4MWifcwHn9' ||
        localStorage.getItem('tenentID') == 'PLzQB8s92mDSj8Z4E' ||
        localStorage.getItem('tenentID') == 't9jzgqr8vcyzwwQsZ' ||
        localStorage.getItem('tenentID') == '57Xs6yMzdimQibLFq'
      ) {
        this.isPulseAllow = true;
      }
      this.displayChat();
      const session = await this.clientProfile;
      if (!session) return;
      // await this.initialize();
      if (Object.keys(this.defaultSettings).length === 0) {
        const user = await this.$repo.user.getUser('me');
        const userDetails = this.defaultSettings;

        this.setDefaultSettings({ ...userDetails, user });
        if (Object.keys(this.userPermissions).length === 0) {
          let permissions = await getAuthoritiesObj(user?.roles);
          this.setUserPermissions(permissions);
        }
      }
      if (Object.keys(this.userPermissions).length > 0) {
        this.generateAllMenus();
      }
      // this.$intercom.shutdown();
      //this.bootIntercom();
      /*if (!this.$intercom.isBooted) {
        this.bootIntercom();
      }
      this.updateIntercom();
      */
      // Gleap.open();
      //this.getLocale
      Gleap.setLanguage(this.getLocale);
      this.updateGleap();
      this.initializeWootrics();
    } catch (err) {
      handleErrorUI(err);
    }
  },
  mounted() {
    this.fetchProfilePicture();

    this.isDemo = this.viewAllowed('FlowAssistantEdit');
    if (this.isAuthenticated) {
      if (localStorage.getItem('storeCodes')) {
        this.setStoreCodes({
          storeCodes: localStorage.getItem('storeCodes').split(',')
        });
      }
      this.setRetailCalendar({
        dates: JSON.parse(localStorage.getItem('retailCalendar'))
      });
      this.setStartingPeriod({
        startingPeriod: localStorage.getItem('startingPeriod')
      });
      this.setEndingPeriod({
        endingPeriod: localStorage.getItem('endingPeriod')
      });
      this.setStartingCompared({
        startingCompared: localStorage.getItem('startingCompared')
      });
      this.setEndingCompared({
        endingCompared: localStorage.getItem('endingCompared')
      });
      this.setLibraryStartingPeriod({
        startingPeriod:
          localStorage.getItem('startingLibraryPeriod') ??
          startOfWeek(new Date())
      });
      this.setLibraryEndingPeriod({
        endingPeriod: localStorage.getItem('endingLibraryPeriod') ?? new Date()
      });
      this.setLibraryStartingCompared({
        startingCompared:
          localStorage.getItem('startingLibraryCompared') ??
          addWeeks(startOfWeek(new Date()), -1)
      });
      this.setLibraryEndingCompared({
        endingCompared:
          localStorage.getItem('endingLibraryCompared') ??
          addWeeks(new Date(), -1)
      });
      this.setCustomCalenderFlag({
        endingCompared: localStorage.getItem('useCustomCalender')
      });

      let selectedKPI = localStorage.getItem('selectedTrafficKPI');

      let url_string = window.location.href;
      let url = new URL(url_string);
      let req = url;
      if (selectedKPI !== undefined) {
        this.getLocalStoreKPI({ selectedKPI, req });
      } else {
        this.getLocalStoreKPI({ selectedKPI: [], req });
      }
    }
  },
  methods: {
    ...mapMutations('user', [
      'setLocale',
      'setUserPermissions',
      'setDefaultSettings',
    ]),

    ...mapMutations('home', ['setPlaceHolder']),
    ...mapMutations('distribution', ['getLocalStoreKPI']),
    ...mapMutations('filter', [
      'setStoreCodes',
      'setStartingPeriod',
      'setEndingPeriod',
      'setStartingCompared',
      'setEndingCompared',
      'setCustomCalenderFlag',
      'setRetailCalendar'
    ]),
    ...mapMutations('marketplace', [
      'setLibraryStartingPeriod',
      'setLibraryEndingPeriod',
      'setLibraryStartingCompared',
      'setLibraryEndingCompared'
    ]),
    ...mapActions('user', ['clearSession', 'fetchKpiPreferences', 'logout']),
    ...mapActions('user', [
      'fetchKpiPreferences'

      // , 'fetchKpiPreferences2'
    ]),
    ...mapActions('tenant', ['reset']),
    ...mapActions({
      initialize: 'tenant/initialize'
    }),
    drawerHandler() {
      
      if(this.isEventAdd){
        this.toggleLeftMiniDrawer = true;
        }else{
        this.toggleLeftMiniDrawer = false;

      }
      this.isEventAdd = !this.isEventAdd;
    },
    handleMouseOver() {
      if (this.isEventAdd) return;
      this.toggleLeftMiniDrawer = false;
    },
    handleMouseOut() {
      if (this.isEventAdd) return;
      this.toggleLeftMiniDrawer = true;
    },
    async fetchProfilePicture() {
      // try {
      const responce = await this.$repo.user.getProfilePicture();
      this.imageURL = responce;
      if(!this.imageURL){
         const {profile} = this.$store.state.user;
         if(profile){
            const {firstName, lastName} = profile;
            const concatName = (firstName[0] || '') + (lastName[0] || '');
            const obj = {name: concatName && concatName.toUpperCase() || '', color:'#999999'}
            this.setPlaceHolder(obj);
         }
      }
      if (this.imageURL == null) {
        this.imgCheck = true;
      } else {
        this.imgCheck = false;
      }
      // } catch () {
      // }
    },
    displayChat() {
      const usrChat = localStorage.getItem('usernameChat');
      const tokenChat = this.getToken;
      const lang = this.getLocale;

      var appStatus = true;
      /*if (localStorage.getItem('status') == 'production') {
        this.appStatus = true;
      } else {
        this.appStatus = false;
      }*/

      var ip = localStorage.getItem('chatIP');
      var isprod = localStorage.getItem('chatIsProd');

      if (usrChat != null) {
        var obj = {
          token: tokenChat,
          lang: lang,
          isSandbox: isprod
        };

        obj.addBotCallback = function() {
          window.open('/Explore', '_self');
        };

        //  window.renderFlowChat('flow-chat', obj);
        window.flowLoadChat('flow-chat', obj);
        window.intercomSettings = {
          alignment: 'right',
          horizontal_padding: 85,
          vertical_padding: 65
        };
      }
    },
    generateAllMenus() {
      this.generateMenu(reportMenu, 'accessibleReportMenu');
      this.generateMenu(VMDMenu, 'accessibleVMDMenu');
      this.generateMenu(toolsMenu, 'accessibleToolsMenu');
      this.generateMenu(academyMenu, 'accessibleAcademyMenu');
      this.generateMenu(settingMenu, 'accessibleSettingMenu');
    },
    displayMenu(name) {
      if (name === 'tools') {
        return ['FlowLogView', 'FlowImportlogView', 'FlowStoreManagementView']
          .map(v => this.viewAllowed(v))
          .some(Boolean);
      }
      return false;
    },
    openServiceLink() {
      window.open('https://www.flow-solutions.com/agreements', '_blank');
    },
    getClicker() {
      Vue.prototype?.$mixpanel?.track('Clicker', {
        referrer: document.referrer
      });
      if (this.getLocale === 'en') {
        window.open('https://www.flow-solutions.com/en/clicker-lp', '_blank');
        return;
      }
      window.open('https://www.flow-solutions.com/clicker-lp', '_blank');
    },
    releaseShows() {
      window.open('https://flow-platform.canny.io/changelog', '_blank');
    },
    apidocumentShows() {
      window.open(
        'https://documenter.getpostman.com/view/11648417/2s9YXo2zjZ#85415275-1ff9-487a-825d-508e60c09bdd',
        '_blank'
      );
    },
    async signout() {
      // const session = await this.$repo.session.fetch();
      try {
        Gleap.clearIdentity();
        await this.logout('');
      } catch (ex) {
        console.log('Error on logout', ex);
      }
    }, // signout
    backToV2() {
      window.open('https://staging-ta.flow-insight.com', '_blank');
    },
    redirectTo(routeName) {
      // if( routeName =='Templates' || routeName =='Explore')
      // {
      //   this.$router.push({ name: routeName }).catch(_ => {});
      //   return;
      // }

      this.link = routeName;
      if (routeName) {
        Vue.prototype?.$mixpanel?.track(`navigate to ${routeName}`, {
          referrer: document.referrer
        });
        this.toggleLeftMiniDrawer = false;
        this.$router.push({ name: routeName }).catch(_ => {});
      }
    },
    openSideBar() {
      this.toggleLeftMiniDrawer = false;
    },

    generateMenu(menuList, accessibleMenuOptions) {
      const accessibleMenu = Object.keys(menuList).map((reportKey, key) => {
        if (this.viewAllowed(Object.values(menuList)[key].code)) {
          return menuList[reportKey];
        }
      });
      this[accessibleMenuOptions] = accessibleMenu.filter(Boolean);
    },
    async setupLocale(locale) {
      this.setLocale({ locale });
      import(
        /* webpackInclude: /(ja|en-us)\.js$/ */
        'quasar/lang/' + locale
      ).then(lang => {
        this.$q.lang.set(lang.default);
      });
      this.$root.$i18n.locale = locale;
      APIUpdateLangSSO({ lang: locale });
      const kpipref = await this.fetchKpiPreferences();
      // const kpipref2 = await this.fetchKpiPreferences2();
      this.$router.go(0);
    }, // setupLocale
    viewAllowed(name) {
      return checkPermission(name);
    },
    generateHash(user_id) {
      const hash = CryptoJS.HmacSHA256(
        user_id,
        '_I7ywUfT_YEV81RC0Kid7HYAw9Posnk3t1KAPT0W'
      );
      const hashInHex = CryptoJS.enc.Hex.stringify(hash);
      return hashInHex;
    },
    bootIntercom() {
      this.$intercom.boot();
    },
    updateIntercom() {
      var addonsId;
      var plansId;
      var addonsIds;
      var plansIds;
      var singlecode;
      var codes;
      const userdetails = this.defaultSettings?.user.response.userDetails ?? {};
      if (userdetails?.addons != 'undefined' && userdetails?.addons != null) {
        addonsId = userdetails?.addons.map(x => {
          return x._id;
        });
        addonsIds = addonsId.join(',');
      }
      if (userdetails?.plans != 'undefined' && userdetails?.plans != null) {
        plansId = userdetails.plans.map(x => {
          return x._id;
        });

        plansIds = plansId.join(',');
        //codes = code.join(",");
      }
      if (userdetails?.plans != 'undefined' && userdetails?.plans != null) {
        singlecode = userdetails.plans.map(x => {
          return x.code;
        });
        codes = singlecode.join(',');
      }
      if (addonsIds == null || addonsIds == 'undefined') {
        addonsIds = '';
      }
      userdetails.tenant.intercom.addonsIds = addonsIds;
      userdetails.tenant.intercom.plansIds = plansIds;
      userdetails.tenant.intercom.plan = codes;
      const user = this.clientProfile ?? {};
      var phone =
        userdetails?.profile?.phoneCountryCode +
        '' +
        userdetails?.profile?.phoneNumber;
      this.$intercom.boot({
        user_id: user?._id ?? '',
        name: `${user?.fullName}`,
        email: user?.email,
        phone: phone,
        user_hash: this.generateHash(user?._id ?? ''),
        language_override: this.getLocale,
        company: {
          company_id: userdetails?.tenant?.intercom?.companyId,
          name: userdetails?.tenant?.intercom?.companyName,
          plan: userdetails?.tenant?.intercom?.plan
        },
        custom_attributes: {
          addonsIds: userdetails?.tenant?.intercom?.addonsIds,
          plansIds: userdetails?.tenant?.intercom?.plansIds
        }
      });

      /* const user = this.clientProfile ?? {};
          this.$intercom.boot({
            user_id: user?._id ?? '',
            name: `${user?.fullName}`,
            email: user?.email,
            phone: user?.phone,
            user_hash: this.generateHash(user?._id ?? ''),
            language_override: this.getLocale,
            company: {
              company_id: user?.tenant?.intercom?.companyId,
              name: user?.tenant?.intercom?.companyName
            }
          });*/

      // this.$intercom.show();
    },
    updateGleap() {
      var addonsId;
      var plansId;
      var addonsIds;
      var plansIds;
      var singlecode;
      var codes;
      const userdetails = this.defaultSettings?.user.response.userDetails ?? {};
      if (userdetails?.addons != 'undefined' && userdetails?.addons != null) {
        addonsId = userdetails?.addons.map(x => {
          return x._id;
        });
        addonsIds = addonsId.join(',');
      }
      if (userdetails?.plans != 'undefined' && userdetails?.plans != null) {
        plansId = userdetails.plans.map(x => {
          return x._id;
        });

        plansIds = plansId.join(',');
        //codes = code.join(",");
      }
      if (userdetails?.plans != 'undefined' && userdetails?.plans != null) {
        singlecode = userdetails.plans.map(x => {
          return x.code;
        });
        codes = singlecode.join(',');
      }
      if (addonsIds == null || addonsIds == 'undefined') {
        addonsIds = '';
      }
      userdetails.tenant.intercom.addonsIds = addonsIds;
      userdetails.tenant.intercom.plansIds = plansIds;
      userdetails.tenant.intercom.plan = codes;
      const user = this.clientProfile ?? {};
      var phone =
        userdetails?.profile?.phoneCountryCode +
        '' +
        userdetails?.profile?.phoneNumber;

      Gleap.identify(user?._id ?? '', {
        name: `${user?.fullName}`,
        email: user?.email,
        phone: phone,
        //value: 199.95,
        plan: userdetails?.tenant?.intercom?.plan,
        companyId: userdetails?.tenant?.intercom?.companyId,
        companyName: userdetails?.tenant?.intercom?.companyName
      });
    },
    initializeWootrics() {
      let wootricScript = document.createElement('script');
      wootricScript.setAttribute(
        'src',
        'https://cdn.wootric.com/wootric-sdk.js'
      );
      wootricScript.async = true;
      wootricScript.onload = () => {
        window.wootricSettings = {
          email: this.clientProfile?.email,
          created_at: Math.floor(Date.now() / 1000), //Date.now(),
          account_token: 'NPS-c96acd0d' // This is your unique account token.
        };
        window.wootric('run');
      };

      document.head.appendChild(wootricScript);
    },
    updateMixpanelUser() {
      Vue.prototype?.$mixpanel?.people.unset([
        '$email',
        '$phone',
        '$first_name',
        '$last_name'
      ]);
      Vue.prototype?.$mixpanel?.people.set({
        $store_names: this.getStoreNameList,
        $store_codes: this.getStoreCodeList
      });
    },
    toAppStore() {
      window.open(
        'https://apps.apple.com/jp/app/flow-for-mobile/id1504601424?hsCtaTracking=a4ca061c-5db4-4d74-9817-d9af59ea22fe%7C6c5b6e22-41e3-4b28-9ed3-82c5eb04de84',
        '_target'
      );
    },
    toPlayStore() {
      window.open(
        'https://play.google.com/store/apps/details?id=com.flow.go&hl=ja&gl=JP&hsCtaTracking=3f15eddd-e298-4af9-a58b-0bf9de9562b1%7Cbb279490-87f4-47ee-b762-4c61ed6a57a7',
        '_target'
      );
    }
  } // methods
}; // export default
